import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { getFormError, emailPhoneUseridValidator } from '../util';
import { CommonService } from '../common.service';
declare var $: any;

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  contactform: FormGroup;
  requestError = '';
  requestMessage = '';
  data : {};
  getFormError = getFormError;
  visible = true;
  constructor(private fb: FormBuilder,private cs: CommonService) { }

  ngOnInit() {
    this.contactform = this.fb.group({
      FirstName: ['', [Validators.required]],
      LastName: ['', [Validators.required]],
      Email: ['', [Validators.required, emailPhoneUseridValidator, Validators.pattern(/^[ A-Za-z0-9_@./#&+,!$%^();:"'*=-]*$/)]],
      Phone: ['', [Validators.required]],
      Message: ['', [Validators.required]],
    });

    this
  }

  contactsubmit() {
    this.requestError = '';
    this.requestMessage = '';
    if (this.contactform.invalid) {
      this.contactform.markAllAsTouched();
      return;
    }
    let data = this.contactform.getRawValue();
    this.cs.request('/public/contact', 'post', data).then((res: any) => {
      if (res.Status == 200) {
        this.requestMessage = res.Message;
        this.visible = false;
      } else {
        this.requestError = res.Message;
      }
    }).catch((err) => {
    })
  }

}
