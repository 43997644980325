import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
// animation module to fix the error coming in the inspect element
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgSelectModule } from '@ng-select/ng-select';
import { CarouselModule } from 'primeng/carousel'; 
import { ButtonModule } from 'primeng/button'; 
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { HttpClientModule } from '@angular/common/http';
import { SortPipe } from './pipes/sort.pipe';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { MatRadioModule } from '@angular/material/radio';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatCheckboxModule, MatChipsModule, MatIconModule, MatFormFieldModule, MatDialogModule, MatSelectModule, MatSnackBarModule } from '@angular/material';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { MatListModule } from '@angular/material/list';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material';
import { NgxImageCompressService } from 'ngx-image-compress';
import { TableModule } from 'primeng/table';
import { PaginatorModule } from 'primeng/paginator';
import { NgNavigatorShareService } from 'ng-navigator-share';
import { ClipboardModule } from 'ngx-clipboard';
import {DataViewModule} from 'primeng/dataview';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';

import { MapComponent } from './map/map.component';
import { AppComponent } from './app.component';
import { MainComponent } from './main/main.component';
import { LoginComponent } from './login/login.component';
import { FooterComponent } from './footer/footer.component';
import { PasswordComponent } from './password/password.component';
import { TermsComponent } from './terms/terms.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { AppRoutingModule, CanActivateLogin } from './app-routing.module';
import { ContactComponent } from './contact/contact.component';
import { HeaderComponent } from './header/header.component';
import { SettingComponent } from './setting/setting.component';
import { SearchComponent } from './search/search.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { UnsubscribeComponent } from './unsubscribe/unsubscribe.component';
import { AboutUsComponent } from './about-us/about-us.component';

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    HeaderComponent,
    MapComponent,
    ContactComponent,
    LoginComponent,
    SortPipe,
    FooterComponent,
    PasswordComponent,
    TermsComponent,
    PrivacyComponent,
    SettingComponent,
    SearchComponent,
    DashboardComponent,
    UnsubscribeComponent,
    AboutUsComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    NgSelectModule,
    CarouselModule,
    ButtonModule,
    FormsModule,
    HttpClientModule,
    NgxMaterialTimepickerModule,
    Ng2SmartTableModule,
    AutocompleteLibModule,
    GooglePlaceModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    MatRadioModule,
    MatCheckboxModule,
    MatChipsModule,
    MatIconModule,
    MatFormFieldModule,
    MatDialogModule,
    ScrollingModule,
    MatTabsModule,
    MatListModule,
    MatTableModule,
    MatPaginatorModule,
    MatSelectModule,
    TableModule,
    PaginatorModule,
    ClipboardModule,
    MatSnackBarModule,
    DataViewModule,
    MatDatepickerModule,
    MatNativeDateModule
  ],
  providers: [CanActivateLogin,HttpClientModule,NgNavigatorShareService, NgxImageCompressService, { provide: LocationStrategy, useClass: HashLocationStrategy }],
  bootstrap: [AppComponent]
})
export class AppModule { }
