import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ClipboardService } from 'node_modules/ngx-clipboard';
import { MatSnackBar, MatDialog } from '@angular/material';
import { CommonService } from '../common.service';
declare var $: any, google: any;
@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit, AfterViewInit {
  private _map = null;
  message = '';
  error = '';

  constructor(public cs: CommonService, private _snackBar: MatSnackBar, private _clipboardService: ClipboardService) { 
  }
  ngOnInit() {    
    this.cs.spinner = false;
  }

  ngAfterViewInit() {
    if(this._map == null)
    {
      var mapDiv = document.createElement('div');
      this._map = new google.maps.Map(mapDiv);      
    }
  }
  share(){
    var url = 'https://bjfriends.org';

    this._clipboardService.copy(url);
    this._snackBar.open("Copied to Clipboard - " + url, "", {
      duration: 2000,
    });
  }
  clearMessageAndError(){
    this.error = '';
    this.message = '';
  }

  handleCurrentLocationAddressChange(event) {
    var street_number = '';
    var route = '';

    if(this.cs.events.topInitiatedEventData.HomeAddress == null){
      this.cs.events.topInitiatedEventData.HomeAddress = {};
    }
    this.cs.events.topInitiatedEventData.HomeAddress.Unit = '';

    for (let address of event.address_components) {
      if (address.types.indexOf("locality") != -1) {
        this.cs.events.topInitiatedEventData.HomeAddress.City = address.short_name
      } else if (address.types.indexOf("route") != -1) {
        route = address.short_name;
      } else if (address.types.indexOf("street_number") != -1) {
        street_number = address.short_name;
      }else if (address.types.indexOf("administrative_area_level_1") != -1) {
        this.cs.events.topInitiatedEventData.HomeAddress.State = address.short_name;
      }else if (address.types.indexOf("administrative_area_level_2") != -1) {
        this.cs.events.topInitiatedEventData.HomeAddress.County = address.short_name;
      } else if (address.types.indexOf('postal_code') != -1) {
        this.cs.events.topInitiatedEventData.HomeAddress.Zip = address.long_name;
      } else if (address.types.indexOf('country') != -1) {
        this.cs.events.topInitiatedEventData.HomeAddress.Country = address.short_name;
      }
    }
    if(this.cs.events.topInitiatedEventData.HomeAddress.Country == null || this.cs.events.topInitiatedEventData.HomeAddress.Country == undefined || this.cs.events.topInitiatedEventData.HomeAddress.Country == '') {
      this.cs.events.topInitiatedEventData.HomeAddress.City = '';
      return;
    }

    this.cs.events.topInitiatedEventData.HomeAddress.Lat = event.geometry.location.lat();
    this.cs.events.topInitiatedEventData.HomeAddress.Lng = event.geometry.location.lng();

    this.cs.events.topInitiatedEventData.HomeAddress.Street = street_number + ' ' + route;
    this.cs.events.topInitiatedEventData.HomeAddress.Zip = '';
    this.cs.events.topInitiatedEventData.HomeAddress.Error = '';
  }

  handleOriginAddressChange(event) {

    var street_number = '';
    var route = '';

    if(this.cs.events.topInitiatedEventData.Origin == null){
      this.cs.events.topInitiatedEventData.Origin = {};
    }
    this.cs.events.topInitiatedEventData.Origin.Unit = '';

    for (let address of event.address_components) {
      if (address.types.indexOf("locality") != -1) {
        this.cs.events.topInitiatedEventData.Origin.City = address.short_name
      } else if (address.types.indexOf("route") != -1) {
        route = address.short_name;
      } else if (address.types.indexOf("street_number") != -1) {
        street_number = address.short_name;
      }else if (address.types.indexOf("administrative_area_level_1") != -1) {
        this.cs.events.topInitiatedEventData.Origin.State = address.short_name;
      } else if (address.types.indexOf('postal_code') != -1) {
        this.cs.events.topInitiatedEventData.Origin.Zip = address.long_name;
      } else if (address.types.indexOf('country') != -1) {
        this.cs.events.topInitiatedEventData.Origin.Country = address.short_name;
      }
    }
    this.cs.events.topInitiatedEventData.Origin.Street = street_number + ' ' + route;
    this.cs.events.topInitiatedEventData.Origin.Zip = '';
    this.cs.events.topInitiatedEventData.Origin.Error = '';

    if(this.cs.events.topInitiatedEventData.Origin.Country == null || this.cs.events.topInitiatedEventData.Origin.Country == undefined || this.cs.events.topInitiatedEventData.Origin.Country == ''||
    //this.cs.events.topInitiatedEventData.Origin.Country != 'IN' ||
    this.cs.events.topInitiatedEventData.Origin.State == null || this.cs.events.topInitiatedEventData.Origin.State == undefined || this.cs.events.topInitiatedEventData.Origin.State == ''
    /*|| (this.cs.events.topInitiatedEventData.Origin.State != 'BR' && this.cs.events.topInitiatedEventData.Origin.State != 'JH' && this.cs.events.topInitiatedEventData.Origin.State != 'UP' ) */) {
      this.cs.events.topInitiatedEventData.Origin.City = '';
      return;
    }

    this.cs.events.topInitiatedEventData.Origin.Lat = event.geometry.location.lat();
    this.cs.events.topInitiatedEventData.Origin.Lng = event.geometry.location.lng();

  }

  update(){
    this.clearMessageAndError();
    if(isNaN(this.cs.events.topInitiatedEventData.RsvpCount)){
      this.error = 'Please enter a valid family member count';
      return;
    }
    //this.cs.events.topInitiatedEventData.RsvpCount = 2;
    this.cs.request('/public/event', 'post', this.cs.events.topInitiatedEventData).then((res: any) => {
      if (res.Status == 200) {
        this.message = res.Message;
      } else {
        this.error = res.Message;
      }
    }).catch((err) => {
    });
  }
}
