import { Component, OnInit, AfterViewInit, Input } from '@angular/core';
import { CommonService } from '../common.service';
import { Router } from '@angular/router';
declare var $: any;

declare global {
  interface Window { MSStream: any; }
}
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit, AfterViewInit {
  @Input() showComponent = true;
  @Input() loginFilters;
  constructor(public cs: CommonService, private router: Router) {

  }

  ngOnInit() {
    // console.log('user', this.cs.user)
    const IS_IOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

    if (IS_IOS) {
      $('body,html').addClass('ios');
      $('#main-nav').find('li').addClass('remove-psudo');
    }

    if (this.router.url == "/") {
      $('.logo-img').addClass('only-home-logo');
    }

    $('body,html').click(function (e) {
      var elem = document.getElementById("mySidenav");
      // console.log('item',elem)
      if (!$(".fa-bars").is(e.target) && !$("#mySidenav").is(e.target) && $("#mySidenav").has(e.target).length === 0) {
        if (elem) {
          elem.style.width = "0";
        }
        document.getElementById("nav-content").style.opacity = "0";
      }
    });

    if (this.loginFilters) {
      localStorage.setItem("filters", JSON.stringify(this.loginFilters));
    }
  }

  ngAfterViewInit() {
    if ((window.location.href.indexOf("/login") > -1) || (window.location.href.indexOf("/thank-you-for-booking") > -1) || (window.location.href.indexOf("/product") > -1) || (window.location.href.indexOf("/payment") > -1) || (window.location.href.indexOf("/addresses") > -1) || (window.location.href.indexOf("/profile") > -1) || (window.location.href.indexOf("/thank-you-for-booking") > -1) || (window.location.href.indexOf("/product") > -1) || (window.location.href.indexOf("/payment") > -1) || (window.location.href.indexOf("/addresses") > -1) || (window.location.href.indexOf("/settings") > -1) || (window.location.href.indexOf("/account") > -1) || (window.location.href.indexOf("/customers") > -1) || (window.location.href.indexOf("/bookmarks") > -1) || (window.location.href.indexOf("/dashboard") > -1) || (window.location.href.indexOf("/order") > -1) || (window.location.href.indexOf("/payment") > -1) || (window.location.href.indexOf("/feedback") > -1) || (window.location.href.indexOf("/contact") > -1)) {
      $('header').removeAttr('id');
      $('header').removeAttr('style');
      $('header').addClass('site-login-header');
      if (!this.cs.mobilecheck) {
        $('body').css('overflow', 'hidden');
      }
    }
    if (window.location.href.indexOf("/booking-process") > -1) {
      $('header').removeAttr('id');
      $('header').removeAttr('style');
      $('header').addClass('site-login-header');
    }
    const IS_IOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

    if (IS_IOS) {
      $('#main-nav').find('li').addClass('remove-psudo');
    }
  }

  openLogin(page) {
    this.router.navigateByUrl('/login', { state: { page } });
  }

  openNav() {
    document.getElementById("mySidenav").style.width = "155px";
    document.getElementById("nav-content").style.opacity = "1";
  }

  closeNav() {
    document.getElementById("mySidenav").style.width = "0";
    document.getElementById("nav-content").style.opacity = "0";
  }

  closeImageModal(){
    var modal = document.getElementById("ImageModal");
    modal.style.display = "none";
  }

}
