import { Component, OnInit, AfterViewInit } from '@angular/core';
declare var google: any;
@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, AfterViewInit {

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit(){
    var ua = window.navigator.userAgent;
  var is_ie = /MSIE|Trident/.test(ua);
  var is_edge = /Edge\./i.test(ua);
  var edge = ua.indexOf('Edge/');
  if (edge > 0) {
    // Edge (IE 12+) => return version number
    is_edge = true;
  }

  if (is_ie || is_edge) {
    var _map = new google.maps.Map(document.getElementById('map-container'), {
      mapTypeControlOptions: {
        style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
        position: google.maps.ControlPosition.BOTTOM_LEFT
      },
      center: {
        lat: 39.8097343,
        lng: -98.5556199
      },
      zoom: 5
    });
  } else {
    var _map = new google.maps.Map(document.getElementById('city-name'), {
      mapTypeControlOptions: {
        style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
        position: google.maps.ControlPosition.BOTTOM_LEFT
      },
      center: {
        lat: 39.8097343,
        lng: -98.5556199
      },
      zoom: 5
    });
  }
  }

}
