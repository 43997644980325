import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { getFormError, emailPhoneUseridValidator } from '../util';
import { CommonService } from '../common.service';
declare var $: any;

@Component({
  selector: 'app-contact',
  templateUrl: './unsubscribe.component.html',
  styleUrls: ['./unsubscribe.component.scss']
})
export class UnsubscribeComponent implements OnInit {
  unsubscribeform: FormGroup;
  requestError = '';
  requestMessage = '';
  getFormError = getFormError;

  constructor(private fb: FormBuilder,private cs: CommonService) { }

  ngOnInit() {
    this.unsubscribeform = this.fb.group({
      Email: ['', [Validators.required, emailPhoneUseridValidator, Validators.pattern(/^[ A-Za-z0-9_@./#&+,!$%^();:"'*=-]*$/)]],
      Subscribe: ['', []],
    });
  }

  unsubscribesubmit() {
    this.requestError = '';
    this.requestMessage = '';
    if (this.unsubscribeform.invalid) {
      this.unsubscribeform.markAllAsTouched();
      return;
    }
    let formdata = this.unsubscribeform.getRawValue();

    var data: any = {};
    data.Name = formdata.Email;
    data.Value = formdata.Subscribe.toString();
    
    this.cs.request('/public/unsubscribe', 'post', data).then((res: any) => {
      if (res.Status == 200) {
        this.requestMessage = res.Message;
      } else {
        this.requestError = res.Message;
      }
    }).catch((err) => {
    })

  }

}
