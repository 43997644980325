import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { CommonService } from '../common.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {
  @Output() goToLogin = new EventEmitter<any>();
  constructor(public cs: CommonService, private router : Router) { }

  ngOnInit() {
  }

  acceptTerms(){
    this.cs.request('/ApplicationUsers/AcceptTerms', 'post').then((res: any) => {
      if (res.status == "true") {            
        this.goToLogin.emit(true);
        this.cs.TermsAndConditionFlag = true;
        document.cookie = "TermsAndConditionFlag=" + this.cs.TermsAndConditionFlag.toString();   
        this.router.navigateByUrl('/' + this.cs.loginSignupRedirect);
      }
    }).catch((err) => {
    })  

  }
}
