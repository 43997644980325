import { Component, OnInit, Input } from '@angular/core';
import { CommonService } from '../common.service';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  @Input() showComponent = true;
  @Input() stepsScreen = false;
  @Input() sticky = false;
  url : string;
  constructor(public cs : CommonService,public router : Router) { }

  ngOnInit() {
    this.url = this.router.url;
    this.updateFooter();
    this.router.events.subscribe((event) => {
      if(event instanceof NavigationEnd) {
        this.url = event.urlAfterRedirects;
        this.updateFooter();
      }
    })
  }

  updateFooter(){
    if(this.url == "/" || this.url == "/contact") {
      this.showComponent = true;
    }else{
      this.showComponent = false;
    }
  }

  checkUrl(){
    return false;
  }

}
