import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { getFormError } from '../util';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { CommonService } from '../common.service';
declare var $: any, CryptoJS: any, window: any;

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss']
})
export class PasswordComponent implements OnInit {
  forgetPasswordForm: FormGroup;
  resetPasswordForm: FormGroup;
  getFormError = getFormError;
  requestError = '';
  requestSuccess = '';
  submitSuccess = false;
  isResetPasswordScreen = false;
  isResetPasswordMatched = true;
  VarificationCode = '';
  requestedId = ''
  @Output() goToLogin = new EventEmitter<any>();
  constructor(private fb: FormBuilder, private cs : CommonService, private route: ActivatedRoute) { 
    this.forgetPasswordForm = this.fb.group({
      Email: ['', [Validators.required]]
    });
    this.resetPasswordForm = this.fb.group({
      NewPassword: ['', [Validators.required]],
      RePassword: ['', [Validators.required]],
    });
   }

  ngOnInit() {

    if(this.route.snapshot != null && this.route.snapshot.queryParams != null && this.route.snapshot.queryParams.token != null
      && this.route.snapshot.queryParams.token != ''){
      this.isResetPasswordScreen = true;
      this.VarificationCode = this.route.snapshot.queryParams.token;
    }    
    
  }
  resetSubmit(){
    this.isResetPasswordMatched = true;
    if (this.resetPasswordForm.invalid) {
      this.resetPasswordForm.markAllAsTouched();
      return;
    }
    let data = this.resetPasswordForm.getRawValue();   
    if(data.NewPassword !== data.RePassword){
      this.resetPasswordForm.markAllAsTouched();
      this.isResetPasswordMatched = false;
      return;
    }

    data.VarificationCode = this.VarificationCode;

    this.cs.request('/applicationuser/updatepassword', 'post', {VarificationCode: data.VarificationCode, Password: data.NewPassword}).then((res: any) => {      
      if (res.Status == 200) {        
        this.requestSuccess = res.Message;
        this.requestError = '';
        this.submitSuccess = true;
        this.requestedId = res.requestData.Email;
      } else {
        this.requestSuccess = '';
        this.requestError = res.Message;
      }
    }).catch((err) => {
    })

  }

  forgotSubmit(){
    if (this.forgetPasswordForm.invalid) {
      this.forgetPasswordForm.markAllAsTouched();
      return;
    }

    let data = this.forgetPasswordForm.getRawValue();   
    let auser = {value: data.Email};
    this.requestedId = data.Email;

    this.cs.request('/applicationuser/forgotpassword', 'post', auser).then((res: any) => {      
      if (res.Status == 200) {        
        this.requestSuccess = res.Message;
        this.requestError = '';
        this.submitSuccess = true;
        this.requestedId = res.requestData.Email;
      } else {
        this.requestSuccess = '';
        this.requestError = res.message;
      }
    }).catch((err) => {
    })
  }

}
