import { FormGroup, AbstractControl, ValidatorFn } from '@angular/forms';

export function getFormError(form: FormGroup, control: string, error: string) {
  return (form.get(control).touched && form.get(control).errors && form.get(control).errors[error]);
}

export function emailPhoneUseridValidator(control: AbstractControl): { [key: string]: boolean } | null {  
  if(ValidateEmail(control.value)){      
    return null;
  }else if(ValidateUserId(control.value)){
    return null;
  }else if(isNaN(control.value)){      
    return {'emailPhoneUserid' : true}
      }else{      
    return null;
  }    
};

function ValidateEmail(mail) {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
    return (true)
  }
  return (false)
}

function ValidateUserId(userid) {
  if (/^\w+$/.test(userid)) {
    return (true)
  }
  return (false)
}