import { Component, OnInit } from '@angular/core';
import { CommonService } from '../common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ClipboardService } from 'node_modules/ngx-clipboard';
import { MatSnackBar, MatDialog } from '@angular/material';
import { Location } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CarouselModule } from 'primeng/carousel';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  searchText: string = '';
  type: string = '0';
  view: string = '';
  loggedIn =  false;
  data:any = [];
  item:any = {Id:0, HomeAddress:{}};
  itemType:any = {};
  message = '';
  error = '';
  TotalCount = 0;
  showingSnackBar = false;
  showingSnackBarItem = null;
  constructor(public cs: CommonService, private location: Location, public http: HttpClient
    , public dialog: MatDialog, private router: Router, private _snackBar: MatSnackBar
    , private route: ActivatedRoute, private _clipboardService: ClipboardService) {
    if (this.cs.user.access_token) {
      this.loggedIn = true;
    }
  }

  ngOnInit() {
    this.route.paramMap.subscribe((params: any) => {
      this.searchText = '';
      if(params != null && params.params != null && params.params.type != null){
        this.view= params.params.type.toLowerCase();
      }
      if(params != null && params.params != null && params.params.keyword != null){
        this.searchText= params.params.keyword.toLowerCase();
      }

      if(this.view == 'event'){
        this.item.Id = -1;
        this.showDetails();
      }
      this.search();
    });
  }

  showDetails(){
    this.clearMessageAndError();
    this.itemType.Id = 1;
    this.cs.request('/public/' + this.view + '?id=' + this.searchText, 'get').then((res: any) => {
      if (res.Status == 200) {
        this.item = res.Data;

      this.item .Pictures = [];
        if(this.item .Picture != null && this.item .Picture != ''){
          var pictures = this.item .Picture.split(' ');
          pictures.forEach(elementPic => {
            this.item .Pictures.push({imageSrc: elementPic})
          });
        }
        this.item.DescriptionFormatted = this.item.Description.replace(/\n/g, '<br/>')
      } else {
        this.error = res.Message;
      }
    }).catch((err) => {
    });
  }

  showDescription(item){
    this.clearMessageAndError();
    if(item.Description != null && item.Description != ''){
      if(this.showingSnackBar == false || item != this.showingSnackBarItem){
          this._snackBar.open(item.Description, "", {duration: 10000 });
          this.showingSnackBarItem = item;
          this.showingSnackBar = true;
      }
      else{
        this._snackBar.dismiss();
        this.showingSnackBar = false;
        this.showingSnackBarItem = null;
      }
    }else {
      this._snackBar.dismiss();
      this.showingSnackBar = false;
      this.showingSnackBarItem = null;
    }
  }

  update(){
    this.clearMessageAndError();
    if(this.view.toLowerCase() == 'event' && isNaN(this.item.RsvpCount)){
      this.error = 'Please enter a valid family member count';
      return;
    }
    this.cs.request('/public/' + this.view.toLowerCase(), 'post', this.item).then((res: any) => {
      if (res.Status == 200) {
        this.message = res.Message;
      } else {
        this.error = res.Message;
      }
    }).catch((err) => {
    });
  }

  clearData(){
    this.itemType = {};
    this.data = [];
    this.error = '';
  }

  handleCurrentLocationAddressChange(event) {
    var street_number = '';
    var route = '';

    if(this.item.HomeAddress == null){
      this.item.HomeAddress = {};
    }
    this.item.HomeAddress.Unit = '';

    for (let address of event.address_components) {
      if (address.types.indexOf("locality") != -1) {
        this.item.HomeAddress.City = address.short_name
      } else if (address.types.indexOf("route") != -1) {
        route = address.short_name;
      } else if (address.types.indexOf("street_number") != -1) {
        street_number = address.short_name;
      }else if (address.types.indexOf("administrative_area_level_1") != -1) {
        this.item.HomeAddress.State = address.short_name;
      }else if (address.types.indexOf("administrative_area_level_2") != -1) {
        this.item.HomeAddress.County = address.short_name;
      } else if (address.types.indexOf('postal_code') != -1) {
        this.item.HomeAddress.Zip = address.long_name;
      } else if (address.types.indexOf('country') != -1) {
        this.item.HomeAddress.Country = address.short_name;
      }
    }
    if(this.item.HomeAddress.Country == null || this.item.HomeAddress.Country == undefined || this.item.HomeAddress.Country == '' ||
    (this.item.HomeAddress.Country != 'US' && this.item.HomeAddress.Country != 'CA')) {
      this.item.HomeAddress.City = '';
      return;
    }

    this.item.HomeAddress.Lat = event.geometry.location.lat();
    this.item.HomeAddress.Lng = event.geometry.location.lng();

    this.item.HomeAddress.Street = street_number + ' ' + route;
    this.item.HomeAddress.Zip = '';
    this.item.HomeAddress.Error = '';
  }

  handleOriginAddressChange(event) {

    var street_number = '';
    var route = '';

    if(this.item.Origin == null){
      this.item.Origin = {};
    }
    this.item.Origin.Unit = '';

    for (let address of event.address_components) {
      if (address.types.indexOf("locality") != -1) {
        this.item.Origin.City = address.short_name
      } else if (address.types.indexOf("route") != -1) {
        route = address.short_name;
      } else if (address.types.indexOf("street_number") != -1) {
        street_number = address.short_name;
      }else if (address.types.indexOf("administrative_area_level_1") != -1) {
        this.item.Origin.State = address.short_name;
      } else if (address.types.indexOf('postal_code') != -1) {
        this.item.Origin.Zip = address.long_name;
      } else if (address.types.indexOf('country') != -1) {
        this.item.Origin.Country = address.short_name;
      }
    }
    this.item.Origin.Street = street_number + ' ' + route;
    this.item.Origin.Zip = '';
    this.item.Origin.Error = '';

    if(this.item.Origin.Country == null || this.item.Origin.Country == undefined || this.item.Origin.Country == ''||
    this.item.Origin.Country != 'IN' ||
    this.item.Origin.State == null || this.item.Origin.State == undefined || this.item.Origin.State == ''
    /*|| (this.item.Origin.State != 'BR' && this.item.Origin.State != 'JH' && this.item.Origin.State != 'UP' ) */) {
      this.item.Origin.City = '';
      return;
    }

    this.item.Origin.Lat = event.geometry.location.lat();
    this.item.Origin.Lng = event.geometry.location.lng();

  }
  
  share(item){
    var url = 'https://api.bjfriends.org/api/public/detail/' + this.view.toLowerCase() + '/' + item.Id;

    this._clipboardService.copy(url);
    this._snackBar.open("Copied to Clipboard - " + url, "", {
      duration: 2000,
    });
  }

  search(){
    //this.type = '';
    this.searchData();
  }

  searchData(){
    this.clearData();
    this.clearMessageAndError();
    var param = 'keyword=' + this.searchText;
    if(this.type != null && this.type != ''){
      param = param + '&type=' + this.type;
    }
    this.cs.request('/public/search?' + param, 'get').then((res: any) => {
      if (res.Status == 200) {
        if(this.item.Id == -1){
          if(res.Data != null && res.Data.length > 0){
            this.item = res.Data[0];
            this.itemType = this.cs.enumTable.SearchType[this.item.Type];
          }
        }
        else {
          this.data = res.Data;
          this.TotalCount = res.TotalCount;
        }
      } else {
        this.error = res.Message;
      }
    }).catch((err) => {
    });
  }

  clearMessageAndError(){
    this.error = '';
    this.message = '';
  }
}
