import { Injectable, Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'sort',
  pure : false
})

@Injectable()
export class SortPipe implements PipeTransform {
  transform(input: any[], key: any): any[] {
    if (!input) return [];
    else {
      return _.orderBy(input, [key]);
    }    
  }
}
