import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { getFormError, emailPhoneUseridValidator } from '../util';
import { CommonService } from '../common.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.scss']
})
export class SettingComponent implements OnInit {
  commandMap: Map<string, string> = new Map();
  keepMenu = true;
  isTopMenuCollasped = false;
  menuCommand = 'personal';
  menuCommandDescription = '';
  enableEdit = false;
  message = '';
  error = '';
  data : any= {};
  constructor(private fb: FormBuilder, public cs: CommonService, 
    private route: ActivatedRoute, private router: Router,
    public breakpointObserver: BreakpointObserver) {
    breakpointObserver.observe([
      '(max-width: 768px)'
        ]).subscribe(result => {
          if (result.matches) {
            this.keepMenu = false;
          } else {
            this.keepMenu = true;
          }
    });   
  }

  ngOnInit() {
    if(this.cs.user.Id == null){
      this.router.navigateByUrl('/');
      return;
    }
    this.cs.spinner = false;
    this.readData();

    this.commandMap.set('personal', 'Personal Details');
    this.commandMap.set('homeaddress', 'Home Address');
    this.commandMap.set('nativeaddress', 'Native Address');
    this.commandMap.set('hobbies', 'Hobbies');
    this.commandMap.set('profession', 'Profession');
    this.commandMap.set('business', 'Business');
    this.commandMap.set('jobs', 'Jobs');
    this.commandMap.set('matrimonial', 'Matrimonial');
    //this.commandMap.set('spouse', 'Spouse');
    //this.commandMap.set('kids', 'Kids');
    this.commandMap.set('about', 'About');

    this.menuCommandDescription = this.commandMap.get(this.menuCommand);
  }
  topMenuClicked(){
    this.isTopMenuCollasped = !this.isTopMenuCollasped;
  }

  menuItemClicked (cmd){
    this.clearMessageAndError();
    this.menuCommand = cmd.toLowerCase();
    this.menuCommandDescription = this.commandMap.get(this.menuCommand);
    this.isTopMenuCollasped = true;

    if (!this.cs.user.access_token){
        this.cs.loginSignupRedirect = 'settings/' + cmd;
        this.router.navigateByUrl('/login');
        return;
    }
  }

  addItemClicked (cmd){
    this.menuCommand = cmd.toLowerCase();
    var itemList = [];
    if(this.menuCommand == 'kids'){
      itemList = this.data.Children;
    }
    itemList.push({Id:0, FirstName:'', LastName:'', Gender:0, DOB:''})
  }  

  deleteItemClicked (cmd, id){
    this.menuCommand = cmd.toLowerCase();
    var itemList = [];
    if(this.menuCommand == 'kids'){
      itemList = this.data.Children;
    }
    for (var i = 0; i < itemList.length; i++) {
      if(itemList[i].Id == id){
        itemList.splice(i, 1);
        break;
      }
    }
  }  

  handleAddressChange(event, addressParam) {
    var street_number = '';
    var route = '';

    addressParam.Unit = '';

    for (let address of event.address_components) {
      if (address.types.indexOf("locality") != -1) {
        addressParam.City = address.short_name;
      } else if (address.types.indexOf("route") != -1) {
        route = address.short_name;
      } else if (address.types.indexOf("street_number") != -1) {
        street_number = address.short_name;
      }else if (address.types.indexOf("administrative_area_level_1") != -1) {
        addressParam.State = address.short_name;
      }else if (address.types.indexOf("administrative_area_level_2") != -1) {
        addressParam.County = address.short_name;
      } else if (address.types.indexOf('postal_code') != -1) {
        addressParam.Zip = address.long_name;
      } else if (address.types.indexOf('country') != -1) {
        addressParam.Country = address.short_name;
      }
    }
    addressParam.Street = street_number + ' ' + route;

    addressParam.Lat = event.geometry.location.lat();
    addressParam.Lng = event.geometry.location.lng();
  }

  verifyEmail(){
    this.clearMessageAndError();
    this.cs.request('/applicationuser/verifyemail', 'post').then((res: any) => {
      if (res.Status == 200) {
        this.message = res.Message;
      } else {
        this.error = res.Message;
      }
    }).catch((err) => {
    })
  }

  verifyPhone(){
    this.clearMessageAndError();
    this.cs.request('/applicationuser/verifyphone', 'post').then((res: any) => {
      if (res.Status == 200) {
        this.message = res.Message;
      } else {
        this.error = res.Message;
      }
    }).catch((err) => {
    })
  }

  updateData(type){
    this.clearMessageAndError();
    this.cs.request('/applicationuser/' + type, 'post', this.data).then((res: any) => {
      if (res.Status == 200) {
        this.message = res.Message;
        this.readData();
        this.edit(false);
      } else {
        this.error = res.Message;
      }
    }).catch((err) => {
    })
  }

  save(){
    this.updateData(this.menuCommand);
  }

  cancel(){
    this.clearMessageAndError();
    this.edit(false);
    this.readData();
  }

  clearMessageAndError(){
    this.error = '';
    this.message = '';
  }

  readData(){
    this.cs.request('/applicationuser', 'get').then((res: any) => {
      if (res.Status == 200) {
        this.data = res.Data;
        if(this.data.Introduction){
          this.data.IntroductionFormatted = this.data.Introduction.replace(/\n/g, '<br/>')
        }
        if(this.data.DOB){
          this.data.DateOfBirthDisplay = new Date(this.data.DOB);
        }
      } else {
        this.error = res.Message;
      }
    }).catch((err) => {
    })
  }
  edit(flag){
    this.enableEdit = flag;
  }
}
