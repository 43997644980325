import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { getFormError, emailPhoneUseridValidator } from '../util';
import { CommonService } from '../common.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import { MatSnackBar } from '@angular/material';
import { ClipboardService } from 'node_modules/ngx-clipboard';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  commandMap: Map<string, string> = new Map();
  keepMenu = true;
  isTopMenuCollasped = false;
  menuCommand = 'event';
  menuCommandDescription = '';
  enableEdit = false;
  message = '';
  error = '';
  data : any = {};
  timeArr = this.cs.getTimeArr();
  content : any= {};
  contents : any= [];
  
  event : any= {};
  events : any= [];
  eventTypes: any= [];

  rsvp : any= {};
  rsvpIndex = -1;
  rsvps : any= [];

  constructor(private fb: FormBuilder, public cs: CommonService, 
    private route: ActivatedRoute, private router: Router,
    public breakpointObserver: BreakpointObserver, private _snackBar: MatSnackBar, private _clipboardService: ClipboardService) {
    breakpointObserver.observe([
      '(max-width: 768px)'
        ]).subscribe(result => {
          if (result.matches) {
            this.keepMenu = false;
          } else {
            this.keepMenu = true;
          }
    });   
  }

  ngOnInit() {
    if(this.cs.user.Id == null || this.cs.IsAdmin == false){
      this.router.navigateByUrl('/');
      return;
    }
    this.cs.spinner = false;

    if(this.cs.IsAdmin || this.cs.IsLeader){
      this.commandMap.set('event', 'Events');
    }
    this.commandMap.set('rsvp', 'RSVPs');;

    this.menuCommandDescription = this.commandMap.get(this.menuCommand);

    this.eventTypes = this.cs.enumTable.EventType.filter(item => item.Value === 'Member');
    if(this.cs.IsLeader){
      this.eventTypes.push(this.cs.enumTable.EventType.filter(item => item.Value === 'Chapter')[0]);
    }
    if(this.cs.IsAdmin){
      this.eventTypes.push(this.cs.enumTable.EventType.filter(item => item.Value === 'Regional')[0]);
    }
    if(this.cs.IsSysAdmin){
      this.eventTypes.push(this.cs.enumTable.EventType.filter(item => item.Value === 'Global')[0]);
    }

    this.menuItemClicked (this.menuCommand);
  }

  topMenuClicked(){
    this.isTopMenuCollasped = !this.isTopMenuCollasped;
  }

  menuItemClicked (cmd){
    if (!this.cs.user.access_token){
      this.cs.loginSignupRedirect = 'settings/' + cmd;
      this.router.navigateByUrl('/login');
      return;
    }
    this.clearMessageAndError();
    this.menuCommand = cmd.toLowerCase();
    this.menuCommandDescription = this.commandMap.get(this.menuCommand);
    this.isTopMenuCollasped = true;

    this.readItems(cmd);
  }

  readItems(cmd){
    this.clearMessageAndError();
    this.rsvpIndex = -1;
    this.menuCommand = cmd.toLowerCase();
    this.cs.request('/dashboard/' + this.menuCommand, 'get').then((res: any) => {
      if (res.Status == 200) {
        this.data = {};
        if(this.menuCommand == 'event'){
          this.events = res.Data;
          this.events.forEach(element => {
            element.ParticipantCount = 0;
            for(let i = 0; i < element.Participant.length; i++) {
              element.ParticipantCount = element.ParticipantCount + parseInt(element.Participant[i].TotalGuestCount);
            }
          });
        }
        if(this.menuCommand == 'content'){
          this.contents = res.Data;
        }
        if(this.menuCommand == 'rsvp'){
          this.rsvps = res.Data;
        }
      } else {
        this.error = res.Message;
      }
    }).catch((err) => {
    })
  }

  rsvpDetails(item)
  {
    this.rsvpIndex = -1;
    if(this.data != null && this.data.Participant != null){
      for(var i=0; i< this.data.Participant.length; i++){
        if(this.data.Participant[i].Id == item.Id){
          this.rsvpIndex = i;
          break;
        }
      }
    }
    this.rsvp = item;
  }

  share(item){
    var url = 'https://bjfriends.org/#/search/' + (this.cs.enumTable.SearchType[item.Type].Value).toLowerCase() + '/' + item.Id;

    this._clipboardService.copy(url);
    this._snackBar.open("Copied to Clipboard - " + url, "", {
      duration: 2000,
    });
  }

  details(item){
    this.data = item;
    if(this.menuCommand == 'event'){
      this.data.DescriptionFormatted = this.data.Description.replace(/\n/g, '<br/>')
      if(this.data.EventDateTime != null){
        this.data.ED = new Date(this.data.EventDateTime);
        
        var h = this.data.ED.getHours();
        var hh = h.toString();
        if(h < 10) {
          hh = '0' + h;
        }

        if(h > 12) {
          hh = (h - 12).toString();
          if((h - 12) < 10 ){
            hh = "0" + hh;
          }
        }

        var m = this.data.ED.getMinutes();
        var mm = h.toString();
        if(m >= 30) {
          mm = '30';
        }else{
          mm = '00';
        }

        this.data.ET = hh + ':' + mm;

        if(h > 12) {
          this.data.ET = this.data.ET + ' pm'
        }else
        {
          this.data.ET = this.data.ET + ' am'
        }
      }
    }
  }
  
  edit(flag){
    this.enableEdit = flag;
  }

  add(){
    this.clearMessageAndError();
    this.data = {};
    this.data.Id = 0;
    this.data.Type = 0;
    this.data.Status = 0;
    this.data.EventAddress = {}
    this.edit(true);
  }  

  deleteItem (cmd, id){
    this.menuCommand = cmd.toLowerCase();
    this.cs.request('/dashboard/' + this.menuCommand, 'delete', id).then((res: any) => {
      if (res.Status == 200) {
        this.message = res.Message;
        this.readItems(this.menuCommand);
        this.edit(false);
      } else {
        this.error = res.Message;
      }
    }).catch((err) => {
    })
  }  

  updateItem(cmd){
    this.clearMessageAndError();
    this.menuCommand = cmd.toLowerCase();

    if(this.menuCommand == 'event'){
      var ampm = this.data.ET.split(' ');
      var hhmm = ampm[0].split(':');

      var aa = ampm[1];
      var hh = parseInt(hhmm[0]);
      if(aa == 'pm') hh =hh + 12;
      
      var hhStr = hh.toString();
      if(hh<10){
        hhStr = "0" + hhStr;
      }

      var eventDate = new Date(this.data.EventDateTime);
      var dd = eventDate.getDate();
      var ddStr = dd.toString();
      if(dd<10){
        ddStr = "0" + ddStr;
      }

      var mm = eventDate.getMonth() + 1;
      var mmStr = mm.toString();
      if(mm<10){
        mmStr = "0" + mmStr;
      }

      var dateStr = eventDate.getFullYear().toString() 
                    + "-" + mmStr 
                    + "-" + ddStr + "T"
                    + hhStr + ":"+ hhmm[1] + ":00";

      this.data.EventDateTime = dateStr;
    }
    this.cs.request('/dashboard/' + this.menuCommand, 'post', this.data).then((res: any) => {
      if (res.Status == 200) {
        this.message = res.Message;
        this.readItems(this.menuCommand);
        this.edit(false);
      } else {
        this.error = res.Message;
        this.edit(false);
      }
    }).catch((err) => {
    })

    this.data = {};
  }

  handleAddressChange(event, addressParam) {
    var street_number = '';
    var route = '';

    addressParam.Unit = '';

    for (let address of event.address_components) {
      if (address.types.indexOf("locality") != -1) {
        addressParam.City = address.short_name;
      } else if (address.types.indexOf("route") != -1) {
        route = address.short_name;
      } else if (address.types.indexOf("street_number") != -1) {
        street_number = address.short_name;
      }else if (address.types.indexOf("administrative_area_level_1") != -1) {
        addressParam.State = address.short_name;
      }else if (address.types.indexOf("administrative_area_level_2") != -1) {
        addressParam.County = address.short_name;
      } else if (address.types.indexOf('postal_code') != -1) {
        addressParam.Zip = address.long_name;
      } else if (address.types.indexOf('country') != -1) {
        addressParam.Country = address.short_name;
      }
    }
    addressParam.Street = street_number + ' ' + route;

    addressParam.Lat = event.geometry.location.lat();
    addressParam.Lng = event.geometry.location.lng();
  }

  save(){
    this.updateItem(this.menuCommand);
  }

  saveCheckin(action, participantData){
    participantData.ParentId = this.data.Id;
    this.cs.request('/dashboard/' + this.menuCommand + '/' + action, 'post', participantData).then((res: any) => {
      if (res.Status == 200) {
        this.message = res.Message;
        //this.readItems(this.menuCommand);
        this.edit(false);
      } else {
        this.error = res.Message;
      }
    }).catch((err) => {
    })
  }

  cancelheckin(){
    this.rsvpIndex = -1;
  }

  cancel(){
    this.clearMessageAndError();
    this.edit(false);
    this.readItems(this.menuCommand);
  }

  clearMessageAndError(){
    this.error = '';
    this.message = '';
  }
}
